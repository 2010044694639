<template>
  <div class="outContainer">
    <div class="search">
        <el-input class="each" v-model="searchInfo.param.title" placeholder="请输入标题"></el-input>
        <el-input class="each" v-model="searchInfo.param.source" placeholder="请输入来源"></el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close"  @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="handleAdd(null)">新增</el-button>
    </div>
    <div class="tableContainer">
        <el-table
         :data="tableData"
         height="100%">
          <el-table-column align='center' label="标题" prop="title">
          </el-table-column>
          <el-table-column align='center' label="来源" prop="source">
          </el-table-column>
          <el-table-column align='center' label="是否置顶" prop="topNum">
            <template slot-scope="scope">
              {{ scope.row.topNum || scope.row.topNum === 0 ? '是' : '否'}}
            </template>
          </el-table-column>
          <el-table-column align='center' label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align='center' label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <p style="margin-bottom: 5px;">
                <el-button size="mini" @click="handleUnTop(scope.row)" v-if="scope.row.topNum || scope.row.topNum === 0">取消置顶</el-button>
                <el-button size="mini" @click="handleTop(scope.row)" v-else>置顶</el-button>
              </p>
              <p>
                <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
                <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
              </p>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <!-- 新增、编辑 -->
    <el-drawer 
      :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%"
      style="height: 100%;">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
    </el-drawer>
  </div>
</template>

<script>
import add from './add.vue';
export default {
  data() {
    return {
        searchInfo:{
            pageNo:1,
            pageSize:10,
            param:{
                title:"",
                source:"",
            }
        },
        tableData:[],
        addDrawer:false,
        row:null,
    }
  },

  components: {
    add,
  },

  computed: {},

  mounted() {},

  methods: {
    // 初始化获取表格数据
    init(){

    },
    // 关闭弹窗
    handleClose(){
        this.addDrawer = false;
        this.init();
    },
    handleAdd(row){
        this.row = row;
        this.addDrawer = true;
    },
  },
};
</script>
<style scoped></style>
